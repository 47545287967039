import { KeyboardEvent, SyntheticEvent, useState } from 'react';
import { logo } from '../../../assets/svg';
import { useAppDispatch } from '../../../hooks';
import { forgotPassword } from '../../../store/slices';
import { PrimaryButton, PrimaryInput } from '../../components/shared';
import { useNavigate } from 'react-router-dom';
import { ForgotPasswordParams } from '../../../types';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [payload, setPayload] = useState<ForgotPasswordParams>({
    email: '',
  });

  const _handleForgot = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!payload.email) return;

    dispatch(forgotPassword({ payload }));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _handleForgot();
    }
  };

  const onGetInputValue = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({ ...payload, [name]: value });
  };

  return (
    <div className="h-screen bg-grey-2 sm:py-12 lg:py-32 flex items-center justify-center">
      <form
        className="flex flex-col items-stretch justify-center bg-white rounded-xl h-full w-full sm:h-auto p-12 sm:w-8/12 lg:w-7/12 xl:w-4/12"
        onSubmit={_handleForgot}
      >
        <img src={logo} alt="logo" className="h-10 w-10 mx-auto" />
        <h1 className="text-neutral-800 bold text-center text-2xl">
          Forgot your password?
        </h1>

        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="Enter email"
          required
          name="email"
          label="Email address"
          className="w-full"
        />

        <PrimaryButton
          disabled={!payload.email}
          text="Send email"
          type="submit"
          classNames="mt-10 medium"
        />

        <span className="text-center">
          <button
            type="button"
            onClick={() => navigate('/auth/login')}
            className="mt-5 text-primary-700 medium focus:outline-none"
          >
            Login
          </button>
        </span>
      </form>
    </div>
  );
};

export default ForgotPassword;
