import { MenuCard } from '.';
import { useFetch } from '../../../hooks';
import { FoodItem, ResolvedResponse } from '../../../types';

export const Menu = () => {
  const { useFetchFoodItem } = useFetch();
  const { data } = useFetchFoodItem<ResolvedResponse<FoodItem[]>>(undefined, {
    count: 500,
  });

  return (
    <div className="mt-32">
      <p className="passion-bold text-center text-black text-[40px] sm:text-[80px] uppercase leading-[100%]">
        Menu
      </p>

      <div>
        <div className="mt-10 grid grid-cols-2 gap-4 px-32">
          {data?.data &&
            data?.data
              .filter((meal) => meal.status === 'OPEN')
              .map((meal) => (
                <div key={meal.id}>
                  <MenuCard key={meal.id} {...meal} />
                </div>
              ))}
        </div>
      </div>

      {/* {meals.length > 0 && hide && (
        <div className="flex justify-center mt-14">
          <button
            className={`rounded-[10px] border-2 border-black bg-primary-brown py-2.5 px-6 text-white uppercase hover:bg-black transition-element-700`}
            onClick={() => setHide(false)}
          >
            See other menu options
          </button>
        </div>
      )} */}
    </div>
  );
};
