import React, { SyntheticEvent } from 'react';
import { foodItemColumns } from '../../../../constants';
import { useAppDispatch, useAppSelector, useFetch } from '../../../../hooks';
import { FoodItem, ResolvedResponse } from '../../../../types';
import {
  Drawer,
  Loading,
  Paginator,
  PrimaryButton,
  PrimarySelect,
  Table,
} from '../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { brandsSelector, getAllBrands } from '../../../../store/slices';
import CreateFoodItem from './create';

const FoodItemsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { brands } = useAppSelector(brandsSelector);
  const { pathname } = useLocation();

  const [page, setPage] = React.useState(1);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [activeBrand, setActiveBrand] = React.useState<number>();

  const { useFetchFoodItem } = useFetch();
  const { data, isLoading } = useFetchFoodItem<ResolvedResponse<FoodItem[]>>(
    undefined,
    { page, count: 45, brand: activeBrand }
  );

  const editItem = (
    id?: string,
    e?: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e && e.stopPropagation();

    if (id) {
      return navigate(`/dashboard/food-items/edit/${id}`);
    }
  };

  const currentPage = React.useMemo(
    () => data?.pagination?.currentPage || 1,
    [data?.pagination?.currentPage]
  );

  const filterFoodItems = (event: SyntheticEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;

    setActiveBrand(parseInt(value));
  };

  const handlePageClick = (data: any) => {
    setPage(data.selected + 1);
  };

  const duplicateItem = (id: string) => {
    if (id) {
      return navigate(`/dashboard/food-items/create/?duplicate=${id}`);
    }
  };

  React.useEffect(() => {
    brands && brands.length < 1 && dispatch(getAllBrands());
    // eslint-disable-next-line
  }, [dispatch]);

  React.useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('create')) {
      setOpenDrawer(true);
    } else {
      setOpenDrawer(false);
    }
  }, [pathname]);

  return (
    <>
      <div className="body1">
        {isLoading && <Loading />}
        <>
          <div className="header1">
            <h1 className="semibold text-xl">Food Item Management</h1>
            <div className="flex space-x-4">
              <PrimaryButton
                text="Create Food Item"
                onClick={() => setOpenDrawer(true)}
              />
            </div>
          </div>
        </>

        <PrimarySelect
          onChange={filterFoodItems}
          placeholder="All Brands"
          options={brands}
          optionLabel="name"
          optionValue="id"
        />

        <Table
          data={data?.data ?? []}
          columns={foodItemColumns}
          tableFor={'foodItems'}
          editMethod={editItem}
          openPage={editItem}
          duplicateMethod={duplicateItem}
        />

        <Paginator
          click={handlePageClick}
          totalPages={data?.pagination?.totalPages || 1}
          selected={currentPage - 1}
          hasNext={data?.pagination?.hasNext || false}
        />
      </div>

      <Drawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        closeDrawer={() => {
          setOpenDrawer(false);
          navigate('/dashboard/food-items');
        }}
      >
        <CreateFoodItem closeDrawer={() => setOpenDrawer(false)} />
      </Drawer>
    </>
  );
};

export default FoodItemsScreen;
