import { stringifyParameters } from '../helpers';
import { IMealsApi } from '../types';
import { instance } from './instance';

export const mealsApi: IMealsApi = {
  getAllMeals: async () => await instance.get('/meal/all/'),

  getMeal: async (slug) => await instance.get(`/meal/${slug}/`),

  createMeal: async (payload) =>
    await instance.post('/admin/meal/create/', payload),

  updateMeal: async (payload, id) =>
    await instance.put(`/admin/meal/${id}/`, payload),

  generateMenu: async (payload) =>
    await instance.post(`/meal/print-menu/`, payload),

  getFoodItems: async (id = '', params) => {
    const payload = stringifyParameters(params);

    const data = await instance.get(`/food-item/${id}${payload}`);
    return data.data;
  },

  createFoodItem: async (payload) => await instance.post(`/food-item`, payload),

  updateFoodItem: async (id, payload) =>
    await instance.put(`/food-item/${id}`, payload),

  getFoodGroups: async (id = '', params) => {
    const payload = stringifyParameters(params);

    return (await instance.get(`/food-group/${id ?? ''}${payload}`)).data;
  },

  getOptions: async (params) => {
    const payload = stringifyParameters(params);

    return (await instance.get(`/food-group/options/${payload}`)).data;
  },

  createOption: async (payload) =>
    await instance.post(`/food-group/options`, payload),

  updateOption: async (id, payload) =>
    await instance.put(`/food-group/options/${id}`, payload),

  createFoodGroup: async (payload) =>
    await instance.post(`/food-group`, payload),

  updateFoodGroup: async (id, payload) =>
    await instance.put(`/food-group/${id}`, payload),

  sendFoodItemsReport: async (query) =>
    await instance.get(`/food-item/reports/send${query}`),

  createDiscount: async (payload) =>
    await instance.post(`/admin/discount/`, payload),

  updateItemDiscount: async (id, payload) =>
    await instance.patch(`/admin/discount/${id}`, payload),
};
