import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '..';
import { categoriesApi } from '../../api';
import {
  ICategory,
  ICreateCategory,
  MarketingCategory,
  RankCategory,
  SetMarketingCategory,
} from '../../types';

interface ICategoryState {
  loading: boolean;
  categories: ICategory[];
  category: ICategory | null;
  marketingCategories: MarketingCategory[];
}

const initialState: ICategoryState = {
  loading: false,
  categories: [],
  category: null,
  marketingCategories: [],
};

export const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCategory.fulfilled, (state) => {
      state.loading = false;
      toast.success('Category created successfully.');
    });
    builder.addCase(createCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCategories.fulfilled, (state, { payload }) => {
      state.categories = payload;
      state.loading = false;
    });
    builder.addCase(getAllCategories.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCategory.pending, (state) => {
      state.loading = true;
      state.category = null;
    });
    builder.addCase(getCategory.fulfilled, (state, { payload }) => {
      state.category = payload as ICategory;
      state.loading = false;
    });
    builder.addCase(getCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCategory.fulfilled, (state) => {
      state.loading = false;
      toast.success('Category updated successfully.');
    });
    builder.addCase(updateCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(rankCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(rankCategory.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(rankCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(removeCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeCategory.fulfilled, (state) => {
      state.loading = false;
      toast.success('Category removed successfully.');
    });
    builder.addCase(removeCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getMarketingCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMarketingCategories.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.marketingCategories = payload;
    });
    builder.addCase(getMarketingCategories.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(setMarketingCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setMarketingCategories.fulfilled, (state, { payload }) => {
      state.loading = false;
      toast.success('Marketing category updated');
    });
    builder.addCase(setMarketingCategories.rejected, (state) => {
      state.loading = false;
    });
  },
});

const createCategory = createAsyncThunk(
  'createCategory',
  async (
    params: { payload: ICreateCategory; cb?: () => void },
    { dispatch }
  ) => {
    try {
      const { payload, cb } = params;
      const { data } = await categoriesApi.createCategory(payload);
      cb && cb();
      dispatch(getAllCategories());
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllCategories = createAsyncThunk('getAllCategories', async () => {
  try {
    const { data } = await categoriesApi.getAllCategories();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getCategory = createAsyncThunk('getCategory', async (param: string) => {
  try {
    const data = await categoriesApi.getCategory(param);
    return data;
  } catch (error: any) {
    throw error;
  }
});

const updateCategory = createAsyncThunk(
  'updateCategory',
  async (
    params: {
      payload: ICreateCategory;
      cb?: () => void;
      id: number;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await categoriesApi.updateCategory(payload, id);
      cb && cb();
      dispatch(getAllCategories());

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const rankCategory = createAsyncThunk(
  'rankCategory',
  async (param: { payload: RankCategory; cb?: () => void }) => {
    try {
      const { payload, cb } = param;
      const data = await categoriesApi.rankCategory(payload);

      cb && cb();
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const updateCategoryRank = createAsyncThunk(
  'updateCategoryRank',
  async (param: { payload: RankCategory; slug: string; cb?: () => void }) => {
    try {
      const { payload, slug, cb } = param;
      const data = await categoriesApi.updateCategoryRank(payload, slug);

      cb && cb();
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const removeCategory = createAsyncThunk(
  'removeCategory',
  async (param: { slug: string; cb?: () => void }) => {
    try {
      const { slug, cb } = param;
      const data = await categoriesApi.removeCategory(slug);

      cb && cb();
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getMarketingCategories = createAsyncThunk(
  'getMarketingCategories',
  async () => {
    try {
      const data = await categoriesApi.marketingCategories();

      return data.data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const setMarketingCategories = createAsyncThunk(
  'setMarketingCategories',
  async (
    params: {
      payload: SetMarketingCategory;
      cb?: () => void;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb } = params;
      const { data } = await categoriesApi.setMarketingCategories(payload);
      cb && cb();
      dispatch(getAllCategories());

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const categorySelector = (state: RootState) => state.categories;
export {
  getCategory,
  rankCategory,
  updateCategory,
  createCategory,
  removeCategory,
  getAllCategories,
  updateCategoryRank,
  setMarketingCategories,
  getMarketingCategories,
};

export default categorySlice.reducer;
