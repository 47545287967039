import { IOrdersApi } from '../types';
import { instance, v2Instance } from './instance';

export const ordersApi: IOrdersApi = {
  getAllOrders: async ({ page, query, count }) =>
    await v2Instance.get(
      `/admin/order/all?count=${count}&page=${page || 1}${query || ''}`
    ),

  getAbandonedOrders: async ({ page, query }) =>
    await instance.get(
      `/admin/order/abandoned?page=${page || 1}${query || ''}`
    ),

  // getAbandonedOrders: async ({ page, query }) =>
  //   await v2Instance.get(
  //     `/admin/order/all?count=10&page=${page || 1}&paid=false`
  //   ),

  getOrder: async (id: number) => await instance.get(`/admin/order/${id}/`),

  createOrder: async (payload) => await instance.post('/admin/order/', payload),

  updateOrder: async (payload, id: number) =>
    await instance.put(`/admin/order/${id}/`, payload),

  deliveryFee: async (address, brands) =>
    await instance.post(`/delivery-rate/calculate-fee`, {
      deliveryAddress: address,
      brands,
    }),

  createDeliveryRequest: async (id) =>
    await instance.post(`admin/order/${id}/request`),

  getOrderBySlug: async (slug: string) =>
    await instance.get(`order/slug/${slug}`),

  exportOrderCsv: async (query) =>
    await instance.get(`/admin/order/csv${query}&paid=true`),

  getOrdersStatus: async () =>
    await instance.get(`/admin/order/analytics/order-status`),

  getOrderCancelReasons: async () => await instance.get(`/cancel-reasons/all`),

  getOrderChannels: async () => await instance.get(`/order-channel/all`),

  payForMe: async (orderId) =>
    await instance.post(`/order/${orderId}/payment-link/`),
};
