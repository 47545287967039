import { stringifyParameters } from '../helpers';
import { ICouponsApi } from '../types';
import { formDataInstance, instance } from './instance';

export const couponsApi: ICouponsApi = {
  getAllCoupons: async (params) => {
    const payload = stringifyParameters(params);

    const data = await instance.get(`/admin/coupons/${payload}`);
    return data.data;
  },

  getCoupon: async (slug) => await instance.get(`/admin/coupons/${slug}`),
  createCoupon: async (payload) =>
    await instance.post('/admin/coupons/', payload),
  updateCoupon: async (payload, id) =>
    await instance.put(`/admin/coupons/${id}`, payload),

  bulkUploadCoupon: async (payload, code) =>
    await formDataInstance.post(`/coupons/${code}/bulk-create`, payload),
};
