import Modal from 'react-modal';
import { optionsColumns } from '../../../../constants';
import { useOptions } from '../../../../hooks';
import {
  IOnChange,
  Loading,
  Paginator,
  PrimaryButton,
  PrimaryInput,
  SecondaryButton,
  SelectInput,
  Table,
} from '../../../components';
import { FoodItem, Option } from '../../../../types';

const OptionsScreen = () => {
  const {
    modal,
    option,
    loading,
    options,
    foodItems,
    isLoading,
    currentPage,
    setModal,
    editOption,
    closeModal,
    createOption,
    duplicateOption,
    handlePageClick,
    onGetInputValue,
    handleMultiSelect,
  } = useOptions();

  return (
    <div className="body1">
      {isLoading && <Loading />}
      <>
        <div className="header1">
          <h1 className="semibold text-xl">Options Management</h1>
          <div className="flex space-x-4">
            <PrimaryButton text="Create option" onClick={() => setModal(1)} />
          </div>
        </div>
      </>

      <Table
        data={options?.data ?? []}
        columns={optionsColumns}
        tableFor={'options'}
        editMethod={editOption}
        duplicateMethod={duplicateOption}
      />

      <Paginator
        click={handlePageClick}
        totalPages={options?.pagination?.totalPages || 1}
        selected={currentPage - 1}
        hasNext={options?.pagination?.hasNext || false}
      />

      <Modal
        isOpen={modal !== 0}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className={`w-11/12 sm:w-[430px] default-modal`}
        overlayClassName="modal-overlay"
      >
        <CreateOption
          loading={loading}
          edit={modal === 2}
          option={option}
          toggle={closeModal}
          createOption={createOption}
          foodItems={foodItems?.data ?? []}
          onGetInputValue={onGetInputValue}
          handleMultiSelect={handleMultiSelect}
        />
      </Modal>
    </div>
  );
};

export default OptionsScreen;

interface CreateOptionProps {
  loading: boolean;
  edit?: boolean;
  option: Option;
  foodItems: FoodItem[];
  toggle: () => void;
  createOption: () => void;
  handleMultiSelect: IOnChange<FoodItem>;
  onGetInputValue: (
    event: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

const CreateOption = ({
  edit,
  option,
  loading,
  foodItems,
  toggle,
  createOption,
  onGetInputValue,
  handleMultiSelect,
}: CreateOptionProps) => {
  return (
    <div className="p-8 bg-white rounded-lg">
      <p className="bold text-xl">{edit ? 'Edit' : 'Create'} option</p>
      <div className="grid grid-cols-1 gap-4 mt-2.5">
        <PrimaryInput
          required
          name="name"
          className="w-full"
          value={option?.name ?? ''}
          onChange={onGetInputValue}
          placeholder="Enter option name"
          label="What is the name of the option?"
        />
        <SelectInput
          singleSelect
          name="foodItem"
          options={foodItems ?? []}
          placeholder="Select food item"
          handleChange={handleMultiSelect}
          label="Link the option to its corresponding food item"
          getOptionValue={(item) => item.id ?? ''}
          getOptionLabel={(item) => `${item.name}`}
          value={foodItems.find((g) => option.foodItem === g.id)}
        />
        <PrimaryInput
          name="price"
          type="number"
          className="w-full"
          value={`${option?.price}`}
          onChange={onGetInputValue}
          placeholder="Enter option price"
          label="What is the price of the option? (leave input empty to set price to 'null')"
        />
      </div>

      <div className="flex items-center space-x-4 mt-12 mb-10">
        <PrimaryButton
          loading={loading}
          disabled={!!!option.name || loading}
          text={`${option.id ? 'Update' : 'Add'} option`}
          onClick={createOption}
        />
        <SecondaryButton text="Cancel" onClick={toggle} />
      </div>
    </div>
  );
};
