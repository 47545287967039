import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { close as closeIcon } from '../../../../assets/svg';
import { useAppDispatch, useFetch } from '../../../../hooks';
import { setMarketingCategories } from '../../../../store/slices';
import {
  FoodItem,
  MarketingCategory,
  ResolvedResponse,
  SetMarketingCategory,
} from '../../../../types';
import {
  IOnChange,
  PrimaryButton,
  PrimaryInput,
  SecondaryButton,
  SelectInput,
} from '../../../components';

interface IUpdateCategory {
  edit?: string;
  category?: MarketingCategory;
  toggle: () => void;
}

export const CreateMarketingCategory: FC<IUpdateCategory> = ({
  edit,
  category,
  toggle,
}) => {
  const dispatch = useAppDispatch();

  const { useFetchFoodItem } = useFetch();
  const { data } = useFetchFoodItem<ResolvedResponse<FoodItem[]>>(undefined, {
    count: 500,
  });

  const [required, setRequired] = useState<boolean>(false);
  const [payload, setPayload] = useState<SetMarketingCategory>({
    name: '',
    foodItemIds: [],
    position: 0,
  });

  const onGetInputValue = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({
      ...payload,
      [name]: name === 'position' ? parseInt(value) : value,
    });
  };

  const onChangeFoodItem: IOnChange<FoodItem> = (item) => {
    setPayload({
      ...payload,
      foodItemIds: (item as FoodItem[]).map((s) => s.id!),
    });
  };

  const _createCategory = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!required) return;

    dispatch(setMarketingCategories({ payload, cb: toggle }));
  };

  useEffect(() => {
    const { name, foodItemIds } = payload;
    setRequired(name.length > 0 && foodItemIds.length > 0);
  }, [payload]);

  useEffect(() => {
    if (edit && category)
      setPayload({
        name: category?.name!,
        position: category?.position ?? '',
        foodItemIds: category?.foodItems
          ? (category?.foodItems as FoodItem[]).map((meal: FoodItem) => meal.id)
          : [],
      });
  }, [category, edit]);

  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center">
        <p className="bold text-2xl">{`${
          edit ? 'Edit' : 'Create'
        } Marketing Category`}</p>
        <button onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      <form className="mt-5" onSubmit={_createCategory}>
        <div className="flex flex-wrap justify-between items-end">
          <PrimaryInput
            onChange={onGetInputValue}
            value={payload?.name}
            placeholder="Enter category name"
            required
            name="name"
            label="What is the name of the category?"
            className="w-5/12 sm:w-full"
          />

          <SelectInput
            name="foodItems"
            label="What food items are in this category?"
            placeholder="Select food items"
            options={data?.data ?? []}
            handleChange={onChangeFoodItem}
            getOptionValue={(item) => `${item.id}`}
            getOptionLabel={(item) =>
              `${item.name} - ${item.brand?.name ?? 'No brand'}`
            }
            value={data?.data?.filter((g) =>
              (payload.foodItemIds as string[])?.includes(g.id!)
            )}
          />

          <PrimaryInput
            onChange={onGetInputValue}
            value={payload?.position ?? ''}
            placeholder="Position"
            name="position"
            label="Set the position of the category"
            className="w-6/12 sm:w-full"
            type="number"
          />
        </div>

        <div className="flex justify-end items-center space-x-4 mt-12">
          <SecondaryButton text="Cancel" onClick={toggle} />
          <PrimaryButton
            disabled={!required}
            text={`${edit ? 'Update' : 'Create'} Category`}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
