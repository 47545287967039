import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '..';
import { mealsApi } from '../../api';
import { CreateFoodItemDiscount, ICreateMeal, IMeal } from '../../types';
import { genQuery } from '../../helpers';

interface IMealState {
  loading: boolean;
  meals: IMeal[];
  meal: IMeal | null;
}

const initialState: IMealState = {
  loading: false,
  meals: [],
  meal: null,
};

export const mealSlice = createSlice({
  name: 'meals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createMeal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMeal.fulfilled, (state) => {
      state.loading = false;
      toast.success('Meal created successfully.');
    });
    builder.addCase(createMeal.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllMeals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllMeals.fulfilled, (state, { payload }) => {
      state.meals = payload;
      state.loading = false;
    });
    builder.addCase(getAllMeals.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getMeal.pending, (state) => {
      state.loading = true;
      state.meal = null;
    });
    builder.addCase(getMeal.fulfilled, (state, { payload }) => {
      state.meal = payload;
      state.loading = false;
    });
    builder.addCase(getMeal.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateMeal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMeal.fulfilled, (state) => {
      state.loading = false;
      toast.success('Meal updated successfully.');
    });
    builder.addCase(updateMeal.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(generateMenu.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(generateMenu.fulfilled, (state) => {
      state.loading = false;
      toast.success('Menu generated successfully.');
    });
    builder.addCase(generateMenu.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(sendFoodItemsReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendFoodItemsReport.fulfilled, (state) => {
      state.loading = false;
      toast.success('Report exported successfully.');
    });
    builder.addCase(sendFoodItemsReport.rejected, (state) => {
      state.loading = false;
    });
  },
});

const createMeal = createAsyncThunk(
  'createMeal',
  async (params: { payload: ICreateMeal; cb?: () => void }, { dispatch }) => {
    try {
      const { payload, cb } = params;
      const { data } = await mealsApi.createMeal(payload);
      cb && cb();
      dispatch(getAllMeals());
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllMeals = createAsyncThunk('getAllMeals', async () => {
  try {
    const { data } = await mealsApi.getAllMeals();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getMeal = createAsyncThunk('getMeal', async (param: string) => {
  try {
    const { data } = await mealsApi.getMeal(param);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const updateMeal = createAsyncThunk(
  'updateMeal',
  async (
    params: {
      payload: ICreateMeal;
      cb?: () => void;
      id: number;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await mealsApi.updateMeal(payload, id);
      cb && cb();
      dispatch(getAllMeals());
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const generateMenu = createAsyncThunk(
  'generateMenu',
  async (params: { payload: { channel: string }; cb?: () => void }) => {
    try {
      const { payload, cb } = params;
      const { data } = await mealsApi.generateMenu(payload);
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const sendFoodItemsReport = createAsyncThunk(
  'sendFoodItemsReport',
  async (params: {
    payload: { from: string | null; till?: string | null };
    cb?: () => void;
  }) => {
    try {
      const { payload, cb } = params;

      const query = genQuery({ dateFrom: payload });

      const { data } = await mealsApi.sendFoodItemsReport(query);
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const addFoodItemDiscount = createAsyncThunk(
  'addFoodItemDiscount',
  async (params: { payload: CreateFoodItemDiscount; cb?: () => void }) => {
    try {
      const { payload, cb } = params;

      const { data } = await mealsApi.createDiscount(payload);
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const mealSelector = (state: RootState) => state.meals;
export {
  createMeal,
  getAllMeals,
  getMeal,
  updateMeal,
  generateMenu,
  sendFoodItemsReport,
  addFoodItemDiscount,
};

export default mealSlice.reducer;
