import React from 'react';
import {
  Loading,
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
  SecondaryButton,
  SelectInput,
} from '../../../../components';
import { add, backArrow, close } from '../../../../../assets/svg';
import { naira, selectionCounts } from '../../../../../constants';
import { useFoodGroups } from '../../../../../hooks';
import Modal from 'react-modal';
import { Option } from '../../../../../types';
import numeral from 'numeral';

const CreateFoodGroup = () => {
  const {
    id,
    modal,
    loading,
    payload,
    options,
    duplicate,
    required,
    foodItems,
    optionList,
    loadingFoodItems,
    loadingFoodGroup,
    goBack,
    setModal,
    setOptions,
    removeOption,
    onGetInputValue,
    createFoodGroup,
    handleChangeOptions,
    handleChangeFoodItems,
    handleChangeCustomization,
  } = useFoodGroups();

  return (
    <div>
      {(((id || duplicate) && loadingFoodGroup) || loadingFoodItems) && (
        <Loading />
      )}
      <div className="px-4 lg:px-8 py-8 w-full">
        <button
          className="flex items-center space-x-2 text-sm"
          onClick={goBack}
        >
          <img src={backArrow} alt="back" />
          <span className="semibold">Back</span>
        </button>

        <div className="mt-4">
          <h1 className="medium text-lg">
            {!id ? 'Create New' : 'Edit'} Group
          </h1>

          <form className="bg-white p-8 mt-2.5" onSubmit={createFoodGroup}>
            <div className="grid grid-cols-2 gap-4">
              <PrimaryInput
                onChange={onGetInputValue}
                value={payload?.name}
                placeholder="Enter group name"
                required
                name="name"
                label="What is the name of the food group?"
                className="w-full"
              />
              <PrimarySelect
                onChange={onGetInputValue}
                options={selectionCounts}
                optionLabel="label"
                value={`${payload.minimumSelection}`}
                optionValue="value"
                label="Minimum number of options to be selected"
                name="minimumSelection"
                placeholder="Select minimum number"
                className="w-full"
              />
              <PrimarySelect
                onChange={onGetInputValue}
                options={selectionCounts}
                optionLabel="label"
                value={`${payload.maximumSelection}`}
                optionValue="value"
                label="Maximum number of options to be selected"
                name="maximumSelection"
                placeholder="Select maximum number"
                className="w-full"
              />
              <PrimaryInput
                onChange={onGetInputValue}
                value={payload?.position ?? 0}
                placeholder="Enter group position"
                required
                name="position"
                label="What is the position of the food group?"
                className="w-full"
              />

              <SelectInput
                name="foodItems"
                label="What food items can use this group?"
                placeholder="Select Food items"
                options={foodItems?.data ?? []}
                handleChange={handleChangeFoodItems}
                getOptionValue={(item) => item.id ?? ''}
                getOptionLabel={(item) => `${item.name}`}
                value={foodItems?.data?.filter((i) =>
                  payload.foodItem?.includes(i.id)
                )}
              />

              <SelectInput
                name="customisations"
                label="What food items can use this group as a customization?"
                placeholder="Select Food items"
                options={foodItems?.data ?? []}
                handleChange={handleChangeCustomization}
                getOptionValue={(item) => item?.id ?? ''}
                getOptionLabel={(item) => `${item.name}`}
                value={foodItems?.data?.filter((i) =>
                  payload.customisations?.includes(i.id)
                )}
              />

              <SelectInput
                name="options"
                label="Add already existing options to this food group"
                placeholder="Select options"
                options={optionList?.data ?? []}
                handleChange={handleChangeOptions}
                getOptionValue={(item) => item?.id ?? ''}
                getOptionLabel={(item) =>
                  `${item.name} - ${naira}${numeral(item.price).format('0,0')}`
                }
                value={options}
              />
            </div>

            <p className="text-lg bold my-4">Options List</p>

            <div>
              {!id && (
                <button
                  className="text-primary-700 medium flex items-center mt-4 focus:outline-none"
                  onClick={() => setModal(true)}
                  type="button"
                >
                  <img src={add} alt="add" className="mr-3" />
                  Add new options
                </button>
              )}

              {options.map(({ name, price }, index) => (
                <div className="flex space-x-5 mt-4" key={name}>
                  {!id && (
                    <button onClick={() => removeOption(index)}>
                      <img src={close} alt="remove" className="h-4 w-4" />
                    </button>
                  )}

                  <div>
                    <p className="text-xs bold">Option name</p>
                    <p className="py-2 px-8 semibold rounded border border-neutral-300">
                      {name}
                    </p>
                  </div>

                  <div>
                    <p className="text-xs bold">Option price</p>
                    <p className="py-2 px-8 semibold rounded border border-neutral-300">
                      {naira}
                      {numeral(price).format('0,0')}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center space-x-4 mt-12">
              <PrimaryButton
                loading={loading}
                disabled={!required || loading}
                text={`${id ? 'Update' : 'Create'} food group`}
                type="submit"
              />
              <SecondaryButton text="Cancel" type="button" onClick={goBack} />
            </div>
          </form>
        </div>
      </div>

      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        ariaHideApp={false}
        className={`w-11/12 sm:w-[430px] default-modal`}
        overlayClassName="modal-overlay"
      >
        <AddOption
          setOptions={setOptions}
          options={options}
          toggle={() => setModal((prev) => !prev)}
        />
      </Modal>
    </div>
  );
};

export default CreateFoodGroup;

const AddOption = ({
  options,
  toggle,
  setOptions,
}: {
  options: Option[];
  toggle: () => void;
  setOptions: (x: Option[]) => void;
}) => {
  const [option, setOption] = React.useState({ name: '', price: 0 });

  const onGetInputValue = (
    event: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setOption({
      ...option,
      [name]: name === 'price' ? parseInt(value) : value,
    });
  };

  const addOption = () => {
    const tempOptions = [...options];
    tempOptions.push(option);

    setOptions(tempOptions);
    toggle();
  };

  return (
    <div className="p-8 bg-white rounded-lg">
      <p className="bold text-xl">Add an option</p>
      <div className="grid grid-cols-1 gap-4 mt-2.5">
        <PrimaryInput
          onChange={onGetInputValue}
          value={option?.name}
          placeholder="Enter option name"
          required
          name="name"
          label="What is the name of the option?"
          className="w-full"
        />
        <PrimaryInput
          onChange={onGetInputValue}
          value={`${option?.price}`}
          placeholder="Enter option price"
          required
          name="price"
          label="What is the price of the option?"
          className="w-full"
          type="number"
        />
      </div>
      <div className="flex items-center space-x-4 mt-12">
        <PrimaryButton
          disabled={!!!option.name}
          text={`Add Option`}
          type="button"
          onClick={addOption}
        />
        <SecondaryButton text="Cancel" type="button" onClick={toggle} />
      </div>
    </div>
  );
};
