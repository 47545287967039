import { FoodItem, ICategory, IMeal, IVariant } from '../types';

const returnMealName = (id: number, meals: IMeal[]) => {
  let mealName;
  for (let i = 0; i < meals.length; i++) {
    if (meals[i].id === id) {
      mealName = meals[i].name;
    }
  }
  return mealName;
};

export const returnFoodItemName = (id: string, meals: FoodItem[]) => {
  let mealName;
  for (let i = 0; i < meals.length; i++) {
    if (meals[i].id === id) {
      mealName = meals[i].name;
    }
  }
  return mealName;
};

const returnVariantName = (id: number, variants: IVariant[]) => {
  let variantName;
  for (let i = 0; i < variants.length; i++) {
    if (variants[i].id === id) {
      variantName = variants[i].name;
    }
  }
  return variantName;
};

const returnMealBrand = (id: number, meals: IMeal[]) => {
  let mealBrand;
  for (let i = 0; i < meals.length; i++) {
    if (meals[i].id === id) {
      mealBrand = meals[i].brandId;
    }
  }
  return mealBrand as number;
};

const returnCategoryName = (id: number, categories: ICategory[]) => {
  let categoryName;
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].id === id) {
      categoryName = categories[i].name;
    }
  }
  return categoryName;
};

const getFullMeal = (id: number, meals: IMeal[]) => {
  let selectedMeal;
  if (meals) {
    meals.map((meal: IMeal) => {
      if (id === meal.id) {
        selectedMeal = meal;
        return selectedMeal;
      }
      return null;
    });
  }
  return selectedMeal;
};

export {
  returnMealName,
  returnMealBrand,
  returnVariantName,
  getFullMeal,
  returnCategoryName,
};
