import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loading } from '../components';
import Auth from './auth';
import Dashboard from './dashboard';
import OrderReceipt from './dashboard/orders/receipt';
import { SocketProvider } from '../../context/socket';
import Menu from './menu';
import ScrollToTop from '../../hooks/scrollToTop';

const Features = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <SocketProvider>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="*" element={<Auth />} />
            <Route path="/auth/*" element={<Auth />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/print-receipt/" element={<OrderReceipt />} />
            <Route path="/menu/:option" element={<Menu />} />
          </Routes>
        </Suspense>
      </SocketProvider>
    </BrowserRouter>
  );
};

export default Features;
