import React from 'react';
import { FoodItem, Option, ResolvedResponse } from '../types';
import { useFetch } from './useFetch';
import { IOnChange } from '../views/components';
import { mealsApi } from '../api';
import { toast } from 'react-toastify';
import { parseError } from '../helpers';
import { useQueryClient } from '@tanstack/react-query';

export const useOptions = () => {
  const [page, setPage] = React.useState(1);
  const [modal, setModal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [option, setOption] = React.useState<Option>({ name: '', price: 0 });

  const queryClient = useQueryClient();

  const { useFetchOptions, useFetchFoodItem } = useFetch();

  const { data: options, isLoading } = useFetchOptions({ count: 45, page });
  const { data: foodItems } = useFetchFoodItem<ResolvedResponse<FoodItem[]>>(
    undefined,
    { count: 500 }
  );

  const currentPage = React.useMemo(
    () => options?.pagination?.currentPage || 1,
    [options?.pagination?.currentPage]
  );

  const handlePageClick = (data: any) => {
    setPage(data.selected + 1);
  };

  const onGetInputValue = (
    event: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === 'price') {
      return setOption({
        ...option,
        price: value === '' ? null : parseInt(value),
      });
    }

    setOption({
      ...option,
      [name]: value,
    });
  };

  const closeModal = () => {
    setModal(0);
    setOption({ name: '', price: 0 });
  };

  const editOption = (id: string) => {
    const o = options?.data?.find((o) => o.id === id);

    if (o) {
      setModal(2);
      setOption({
        ...o,
        foodItem: o.sameAsFoodItem?.id,
      });
    }
  };

  const duplicateOption = (id: string) => {
    const o = options?.data?.find((o) => o.id === id);

    if (o) {
      setModal(1);
      setOption({
        ...o,
        name: `${o.name} copy`,
        foodItem: o.sameAsFoodItem?.id,
      });
    }
  };

  const handleMultiSelect: IOnChange<FoodItem> = (items) => {
    setOption({
      ...option,
      foodItem: (items as FoodItem).id,
    });
  };

  const createOption = async (e?: React.SyntheticEvent<HTMLFormElement>) => {
    e?.stopPropagation();
    e?.preventDefault();
    setLoading(true);

    if (!option.name) return setLoading(false);

    if (!loading) {
      try {
        if (modal === 2 && option.id) {
          await mealsApi.updateOption(option.id!, option);

          toast.success('Option updated!');
        } else {
          await mealsApi.createOption(option);

          toast.success('Option created!');
        }

        queryClient.refetchQueries(['options']);

        closeModal();
      } catch (error) {
        toast.error(parseError(error));
      } finally {
        setLoading(false);
      }
    }
  };

  return {
    page,
    modal,
    option,
    loading,
    options,
    isLoading,
    foodItems,
    currentPage,
    setModal,
    closeModal,
    editOption,
    createOption,
    handlePageClick,
    duplicateOption,
    onGetInputValue,
    handleMultiSelect,
  };
};
