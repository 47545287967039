import { IAuthApi } from '../types';
import { instance, refreshInstance } from './instance';

export const authApi: IAuthApi = {
  login: async (data) => await refreshInstance.post('/auth/login/', data),
  logout: async () => await instance.post('/auth/logout/'),
  refresh: async () => await refreshInstance.post('/auth/refresh/'),
  forgotPassword: async (data) =>
    await instance.post('/password/forgot/', data),
  resetPassword: async (data) => await instance.post('/password/reset/', data),
};
