import { SyntheticEvent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { marketingCategoriesColumns } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  categorySelector,
  getCategory,
  getMarketingCategories,
} from '../../../../store/slices';
import { NoData, PrimaryButton, Table } from '../../../components';
import { CreateMarketingCategory } from './create-marketing-catgeories';
import { MarketingCategory } from '../../../../types';

const MarketingCategories = () => {
  const dispatch = useAppDispatch();
  const { marketingCategories, loading } = useAppSelector(categorySelector);

  const [modal, setModal] = useState<number>(0);
  const [edit, setEdit] = useState('');
  const [category, setCategory] = useState<MarketingCategory>();

  const _toggleModal = (index: number) => {
    setModal(index);
  };

  const _editCategory = (
    slug: string,
    e: SyntheticEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    dispatch(getCategory(slug));
    _toggleModal(2);
    setEdit(slug);

    setCategory(marketingCategories.find((c) => c.name === slug));
  };

  useEffect(() => {
    marketingCategories &&
      marketingCategories.length === 0 &&
      dispatch(getMarketingCategories());
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      {marketingCategories.length > 0 ? (
        <div className="body1">
          <div className="header1">
            <h1 className="semibold text-xl">Marketing Categories</h1>
            <PrimaryButton
              text="Update category list"
              onClick={() => _toggleModal(1)}
            />
          </div>

          <Table
            columns={marketingCategoriesColumns}
            data={marketingCategories}
            editMethod={_editCategory}
            openPage={_editCategory}
            tableFor={'marketingCategories'}
          />
        </div>
      ) : (
        !loading && (
          <NoData
            title="No marketing category"
            description="You haven’t added any category for marketing. Start adding categories by clicking the button below."
            buttonText="Add New Category"
            onclick={() => _toggleModal(1)}
          />
        )
      )}

      <Modal
        isOpen={modal !== 0}
        onRequestClose={() => _toggleModal(0)}
        ariaHideApp={false}
        className={`w-11/12 sm:w-[430px] default-modal`}
        overlayClassName="modal-overlay"
      >
        <CreateMarketingCategory
          edit={edit}
          category={category}
          toggle={() => _toggleModal(0)}
        />
      </Modal>
    </>
  );
};

export default MarketingCategories;
