const brandsColumns: { Header: string; accessor?: string }[] = [
  {
    Header: 'Brand Name',
    accessor: 'name',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

const brandMealColumns = [
  {
    Header: 'Meal',
    accessor: 'name',
  },
  {
    Header: 'Price',
    accessor: 'basePrice',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

const groupColumns = [
  {
    Header: 'Group Name',
    accessor: 'title',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

const groupStatus = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];

const brandStatus = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Opened', value: 'OPENED' },
  { label: 'Closed', value: 'CLOSED' },
];

export {
  brandsColumns,
  brandMealColumns,
  groupColumns,
  groupStatus,
  brandStatus,
};
