import { Cell } from 'react-table';
import { FoodItem } from '../types';

const categoriesColumns = [
  {
    Header: 'Category Name',
    accessor: 'name',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Food items',
    accessor: 'foodItems',
    Cell: ({ row }: Cell) =>
      row.values.foodItems.map((item: FoodItem) => item.name).join(', '),
  },
  {
    Header: 'Action',
  },
];

export const marketingCategoriesColumns = [
  {
    Header: 'Category Name',
    accessor: 'name',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
  {
    Header: 'Food items',
    accessor: 'foodItems',
    Cell: ({ row }: Cell) =>
      row.values.foodItems.map((item: FoodItem) => item.name).join(', '),
  },
  {
    Header: 'Action',
  },
];

export const categoriesRankColumns = [
  {
    Header: 'Category',
    accessor: 'category',
    Cell: ({ row }: Cell) => row.values.category?.name,
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
  {
    Header: 'Food items',
    accessor: 'foodItems',
    Cell: ({ row }: Cell) =>
      row.values.category?.foodItems
        ?.map((item: FoodItem) => item.name)
        .join(', '),
  },
  {
    Header: 'Action',
  },
];

export { categoriesColumns };
