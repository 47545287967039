import { useNavigate } from 'react-router-dom';
import { foodGroupColumns } from '../../../../constants';
import { useFetch } from '../../../../hooks';
import { Loading, Paginator, PrimaryButton, Table } from '../../../components';
import { FoodGroup, ResolvedResponse } from '../../../../types';
import React from 'react';

const FoodGroupsScreen = () => {
  const [page, setPage] = React.useState(1);

  const { useFetchFoodGroup } = useFetch();
  const { data, isLoading } = useFetchFoodGroup<ResolvedResponse<FoodGroup[]>>(
    undefined,
    { page, count: 20 }
  );
  const navigate = useNavigate();

  const currentPage = React.useMemo(
    () => data?.pagination?.currentPage || 1,
    [data?.pagination?.currentPage]
  );

  const handlePageClick = (data: any) => {
    setPage(data.selected + 1);
  };

  const editFoodGroup = (
    id?: string,
    e?: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e && e.stopPropagation();

    if (id) {
      return navigate(`/dashboard/food-groups/edit/${id}`);
    }
  };

  const duplicateGroup = (id: string) => {
    return navigate(`/dashboard/food-groups/create/?duplicate=${id}`);
  };

  return (
    <div className="body1">
      {isLoading && <Loading />}
      <>
        <div className="header1">
          <h1 className="semibold text-xl">Food Groups Management</h1>
          <div className="flex space-x-4">
            <PrimaryButton
              text="Create Food Group"
              onClick={() => navigate('/dashboard/food-groups/create')}
            />
          </div>
        </div>
      </>

      <Table
        data={data?.data ?? []}
        columns={foodGroupColumns}
        tableFor={'foodItems'}
        editMethod={editFoodGroup}
        openPage={editFoodGroup}
        duplicateMethod={duplicateGroup}
      />

      <Paginator
        click={handlePageClick}
        totalPages={data?.pagination?.totalPages || 1}
        selected={currentPage - 1}
        hasNext={data?.pagination?.hasNext || false}
      />
    </div>
  );
};

export default FoodGroupsScreen;
