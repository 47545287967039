import React from 'react';
import { ImgInput } from '../../../../components';
import { ImageUploader } from '../../../../components/image-uploader';
import { deleteIcon } from '../../../../../assets/svg';

export const FoodItemImage = ({
  image,
  index,
  images,
  setImages,
  updateImageUrl,
}: {
  image: string;
  index: number;
  images: string[];
  setImages: (x: string[]) => void;
  updateImageUrl: (x: string, i: number) => void;
}) => {
  const [uploadURL, setUploadURL] = React.useState(true);

  const deleteRow = () => {
    const updatedList = images.filter((_, i) => i !== index);
    setImages(updatedList);
  };

  const update = (x: string) => {
    updateImageUrl(x, index);
  };

  return (
    <div className="w-full mt-8 relative flex space-x-2 items-end">
      <div>
        <div className="flex items-end space-x-1 mt-2 -mb-5">
          <p className="text-xs text-gray-600 uppercase">change Upload type:</p>
          <button
            onClick={() => setUploadURL((prev) => !prev)}
            className="uppercase bold text-primary-700 text-xs z-10"
            type="button"
          >
            {!uploadURL ? 'Upload new image' : 'Input image URL'} &#8595;
          </button>
        </div>
        {uploadURL ? (
          <ImgInput
            name={`imageUrl-${index}`}
            containerClass="w-full"
            value={image ?? ''}
            label={`Food item image ${index + 2}`}
            onChange={(e) => update(e.currentTarget.value)}
          />
        ) : (
          <div className="mt-7">
            <ImageUploader
              name={`image ${index + 2}`}
              getUpload={(val) => update(val)}
            />
          </div>
        )}
      </div>

      <button type="button" onClick={deleteRow} className="mb-4">
        <img src={deleteIcon} alt="delete" className="h-5" />
      </button>
    </div>
  );
};
