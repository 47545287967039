import numeral from 'numeral';

interface MealCard {
  imageUrl?: string | null;
  name?: string;
  description?: string | null;
  price?: number;
}

export const MenuMealCard = ({
  imageUrl,
  name,
  price,
  description,
}: MealCard) => {
  return (
    <div className="w-full sm:w-6/12 xl:w-3/12 mt-10 lg:flex justify-center">
      <div className="rounded-[16px] overflow-hidden sm:w-11/12 xl:w-11/12 mx-auto border">
        <div
          className={`h-56 overflow-hidden rounded-t-[16px] ${
            imageUrl ? '' : 'bg-cream'
          }`}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="background"
              className="object-contain h-80 w-full"
            />
          ) : (
            <p className="text-2xl flex items-center justify-center pt-24 semibold">
              {name}
            </p>
          )}
        </div>

        <div className="px-5 sm:px-7 py-5 rounded-b-[16px] flex-shrink-0 text-base sm:text-4xl sm:leading-[65px]">
          <p className="sora">{name}</p>
          <p className="text-sm sm:text-2xl sm:leading-[45px] sora">
            {description}
          </p>
          <p className="sora mt-4">{`₦${numeral(price).format('0,0')}`}</p>
        </div>
      </div>
    </div>
  );
};

export const MenuCard = ({ imageUrl, name, price, description }: MealCard) => {
  return (
    <div className="w-full p-4 flex items-center border-2 rounded-3xl">
      <div className="w-9/12">
        <p className="medium text-4xl">{name}</p>
        <p className="text-neutral-500 mt-1 text-2xl">{description}</p>
        <p className="text-neutral-800 mt-2 bold text-2xl">{`₦${numeral(
          price
        ).format('0,0')}`}</p>

        {/* <div className="flex items-center space-x-2 mt-2">
          <div className="py-1 px-1.5 flex items-center space-x-1 rounded-full bg-warning-50">
            <img src={star} alt="star" />
            <p className="semibold text-sm text-warning-600">4.9</p>
          </div>

          <div className="py-1 px-1.5 flex items-center space-x-1 rounded-full bg-[#F0F6FD]">
            <img src={clock} alt="clock" />
            <p className="semibold text-sm text-[#1658A3]">{`${estimatedPreparationTime} mins`}</p>
          </div>
        </div> */}
      </div>

      <div className="w-[150px] h-[150px] rounded-xl overflow-hidden flex items-center justify-center bg-slate-100">
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="meal"
            className="h-full w-full object-cover"
          />
        ) : (
          <p className="text-lg medium text-center">{name}</p>
        )}
      </div>
    </div>
  );
};
