import { RouteObject } from 'react-router-dom';
import Login from '../views/features/auth/login';
import ForgotPassword from '../views/features/auth/forgot-password';
import ResetPassword from '../views/features/auth/reset-password';

export const AuthRoutes: RouteObject[] = [
  { path: '/', element: <Login /> },
  { path: '/login', element: <Login /> },
  { path: '/reset-password/*', element: <ResetPassword /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
];
