import moment from 'moment';
import { starRating } from '../../../assets/svg';
import { BrandRating } from '../../../types';
import { useNavigate } from 'react-router-dom';

export const BrandReviewCard = ({ rating, review }: BrandRating) => {
  const navigate = useNavigate();

  const score = Array.from({ length: rating }, (v, i) => i);
  const pilotScore = Array.from(
    { length: review.pilotRating ?? 0 },
    (v, i) => i
  );

  return (
    <div className="bg-white p-6 rounded-md shadow">
      <div className="flex justify-end">
        <button
          className="text-xs text-primary-600 bold"
          onClick={() => navigate(`/dashboard/order/${review.order?.id}`)}
        >
          GO TO ORDER
        </button>
      </div>
      <div className="flex justify-between items-center">
        <p className="text-sm medium">{`${review.order?.user.firstName} ${review.order?.user.lastName}`}</p>
        <p className="text-xs">
          {moment(review.createdAt).format('Do MMM YYYY, H:mm')}
        </p>
      </div>

      <div className="flex mt-2">
        {score.map((index) => (
          <img key={index} src={starRating} alt="star" className="h-4" />
        ))}
      </div>
      <p className="text-sm text-neutral-800 mt-2">{review.foodComment}</p>

      {review.pilotRating && (
        <div className="mt-4">
          <p>Pilot Rating</p>

          <div className="flex mt-2">
            {pilotScore.map((index) => (
              <img key={index} src={starRating} alt="star" className="h-4" />
            ))}
          </div>
          {review.pilotComment && (
            <p className="text-sm text-neutral-800 mt-2">
              {review.pilotComment}
            </p>
          )}
        </div>
      )}

      {review.order?.orderItems && review.order?.orderItems.length > 0 && (
        <div className="mt-5">
          <p>Food items</p>

          {review.order?.orderItems.map((item) => (
            <p className="text-sm text-neutral-800">{item.foodItem.name}</p>
          ))}
        </div>
      )}
    </div>
  );
};
