import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MultiValue, SingleValue } from 'react-select';
import {
  backArrow,
  battery,
  mapDistance,
  orderBox,
} from '../../../../assets/svg';
import {
  orderStatus,
  paymentMethods,
  paymentStatus,
} from '../../../../constants';
import {
  useAppDispatch,
  useAppSelector,
  useFetch,
  useOrder,
  usePermissions,
} from '../../../../hooks';
import {
  getAllKitchens,
  getAllPilots,
  getOrder,
  kitchensSelector,
  orderSelector,
  unassignPilot,
  updateOrder,
} from '../../../../store/slices';
import {
  ICreateOrder,
  IKitchen,
  IPilot,
  IUser,
  OrderItem,
} from '../../../../types';
import {
  GetAddress,
  MultiSelect,
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
  SecondaryButton,
} from '../../../components';
import { OrderItemsSection } from './create/order-items';
import { v4 as uuid } from 'uuid';
import numeral from 'numeral';
import { VehicleIcon } from '../../../../helpers/riders.helpers';

const CreateOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { kitchens } = useAppSelector(kitchensSelector);
  const { order } = useAppSelector(orderSelector);

  const { useFetchAllPilots } = useFetch();
  const { data: pilots } = useFetchAllPilots('', { count: 500 });

  let { action, id } = useParams();
  const { isOwner } = usePermissions();

  const {
    foodItems,
    cartAmount,
    deliveryFee,
    orderItemList,
    addressPayload,
    calculateCart,
    setOrderItemList,
    setAddressPayload,
  } = useOrder();

  const [edit, setEdit] = useState<boolean>(false);
  const [payload, setPayload] = useState<ICreateOrder>({
    notes: '',
    methodOfPayment: 'TRANSFER',
    paidFor: false,
    status: 'PLACED',
  });

  const onGetInputValue = (
    event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === 'paidFor' && typeof value === 'string') {
      return setPayload({
        ...payload,
        [name]: value === '0' ? false : true,
      });
    }

    if (name === 'pilot') {
      return setPayload({
        ...payload,
        [name]: parseInt(value),
      });
    }

    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const removePilot = async () => {
    dispatch(
      unassignPilot({
        id: order!.id,
        cb: () => dispatch(getOrder(order!.id)),
      })
    );
  };

  const onGetMultiSelectValue = (
    value:
      | MultiValue<{ value: number; label: string }>
      | SingleValue<{ value: number | IUser; label: string }>
  ) => {
    if (Array.isArray(value)) {
      setPayload({ ...payload, kitchens: value.map((item) => item.value) });
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const ordersPage = useCallback(() => {
    navigate('/dashboard/orders');
  }, [navigate]);

  const orderPage = useCallback(() => {
    navigate(`/dashboard/order/${id}`);
  }, [navigate, id]);

  const _createOrder = async (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (order?.pilotId && payload.pilot === undefined) {
      await removePilot();
    }

    if (id) {
      return dispatch(
        updateOrder({
          payload: {
            ...payload,
            orderItems: orderItemList,
            deliveryAddress: addressPayload,
          },
          id: parseInt(id),
          cb: orderPage,
        })
      );
    }
  };

  useEffect(() => {
    kitchens && kitchens.length === 0 && dispatch(getAllKitchens());

    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (edit && order !== null) {
      dispatch(getAllPilots(order.kitchens[0].slug));

      const orderData: OrderItem[] = order.orderItems.map((i) => {
        return {
          token: uuid(),
          name: i.foodItem.name,
          brand: order.brands[0],
          foodItem: i.foodItem.id,
          price: i.price,
          quantity: i.quantity,
          customisations: i.customisations.map((c) => {
            return {
              option: c.option.id!,
              quantity: c.quantity,
              name: c.option.name ?? '',
              price: c.option.price ?? 0,
            };
          }),
          options: i.options.map((c) => {
            return {
              option: c.option.id!,
              quantity: c.quantity,
              name: c.option.name ?? '',
              price: c.option.price ?? 0,
            };
          }),
        };
      });

      setPayload({
        pilot: order.pilotId,
        notes: order.notes,
        methodOfPayment: order.methodOfPayment,
        paidFor: order.paidFor,
        kitchens: order.kitchens
          ? order?.kitchens.map((kitchen: IKitchen) => kitchen?.id!)
          : [],
        status: order.status,
      });

      setAddressPayload(order.deliveryAddress!);

      setOrderItemList(orderData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, edit]);

  useEffect(() => {
    action && action !== 'create' && action !== 'edit' && ordersPage();

    if (action === 'edit' && id) {
      setEdit(true);
      dispatch(getOrder(parseInt(id)));
    } else if (action === 'create' && id) {
      ordersPage();
    }
  }, [action, ordersPage, id, dispatch]);

  return (
    <div className="px-4 lg:px-8 py-8 w-full">
      <button className="flex items-center space-x-2 text-sm" onClick={goBack}>
        <img src={backArrow} alt="back" />
        <span className="semibold">Back</span>
      </button>

      <div className="mt-4">
        <h1 className="medium text-lg">
          {action === 'create' ? 'Create New' : 'Edit'} Order
        </h1>

        <form className="bg-white p-8 mt-2.5" onSubmit={_createOrder}>
          <div>
            <p className="semibold text-neutral-800">Order Information</p>

            <div className="grid xl:grid-cols-2 gap-8 mt-5">
              <OrderItemsSection
                foodItems={foodItems}
                cartAmount={cartAmount}
                orderItemList={orderItemList}
                calculateCart={calculateCart}
                setOrderItemList={setOrderItemList}
              />

              <section className="section">
                <div className="flex justify-between">
                  <div>
                    <h3 className="semibold">Delivery</h3>
                    <p className="text-sm">Delivery & Address Details</p>
                  </div>
                  <p className="text-sm">
                    Delivery Cost: ₦
                    {numeral(deliveryFee?.fee ?? 0).format('0,0')}
                  </p>
                </div>

                <GetAddress
                  edit
                  label="Delivery Address"
                  address={addressPayload}
                  setAddress={setAddressPayload}
                  classNames="w-full"
                />
              </section>
            </div>

            <div className="grid lg:grid-cols-2 gap-3 lg:gap-8">
              <PrimarySelect
                value={payload?.status}
                onChange={onGetInputValue}
                options={orderStatus}
                optionLabel="label"
                optionValue="value"
                label="Order Status"
                name="status"
                placeholder="Select status"
                className="w-full"
                disabled={payload.status === 'SCHEDULED' && !isOwner}
              />
              <PrimaryInput
                onChange={onGetInputValue}
                value={payload.notes}
                placeholder="Enter notes"
                name="notes"
                label="Notes"
                className="w-full"
              />
              <MultiSelect
                name="kitchens"
                className="w-full"
                label="Assigned Kitchens"
                placeholder="Select Kitchens"
                value={
                  payload.kitchens?.length
                    ? (payload.kitchens as number[]).map((kitchen: number) => ({
                        value: kitchen,
                        label: kitchens.find((x) => x.id === kitchen)?.name,
                      }))
                    : undefined
                }
                onChange={(e) => onGetMultiSelectValue(e)}
                options={
                  kitchens &&
                  kitchens.map((kitchen: IKitchen) => ({
                    value: kitchen.id,
                    label: kitchen.name,
                  }))
                }
              />

              <div className="mt-2.5">
                <RiderSelect
                  pilots={pilots?.data ?? []}
                  selectedPilot={pilots?.data?.find(
                    (p) => p.id === payload?.pilot
                  )}
                  selectPilot={(id) =>
                    setPayload({
                      ...payload,
                      pilot: id,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="mt-10">
            <p className="semibold text-neutral-800">Payment Status</p>
            <div className="grid lg:grid-cols-2 gap-3 lg:gap-8">
              <PrimarySelect
                onChange={onGetInputValue}
                options={paymentStatus}
                optionLabel="name"
                value={payload?.paidFor ? 1 : 0}
                optionValue="value"
                label="Payment Status"
                name="paidFor"
                placeholder="Select Payment Status"
                className="w-full"
              />

              <PrimarySelect
                onChange={onGetInputValue}
                options={paymentMethods}
                optionLabel="option"
                optionValue="value"
                value={payload.methodOfPayment ?? ''}
                label="Payment Method"
                name="methodOfPayment"
                placeholder="Select Method of payment"
                className="w-full"
              />
            </div>
          </div>

          <div className="flex items-center space-x-4 mt-12">
            <PrimaryButton
              text={`${edit ? 'Update' : 'Add'} Order`}
              type="submit"
            />
            <SecondaryButton text="Cancel" type="button" onClick={goBack} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOrder;

export const RiderSelect = ({
  pilots,
  selectedPilot,
  selectPilot,
}: {
  pilots: IPilot[];
  selectedPilot?: IPilot;
  selectPilot: (x?: number) => void;
}) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [query, setQuery] = React.useState('');

  const onSelectPilot = (id: number) => {
    selectPilot(id);
    setShowDropdown(false);
    setQuery('');
  };

  return (
    <div className="relative w-full items-end mt-4">
      <div className="mb-1 flex justify-between">
        <p className="text-sm">Pilot</p>
        {selectedPilot && (
          <button
            onClick={() => selectPilot()}
            className="text-sm text-primary-700"
          >
            remove
          </button>
        )}
      </div>

      {selectedPilot ? (
        <PilotButton
          pilot={selectedPilot}
          onClick={() => setShowDropdown((prev) => !prev)}
          className={`p-4 rounded border border-neutral-600 bg-neutral-50 flex justify-between items-center w-full`}
        />
      ) : (
        <input
          type="text"
          value={query}
          placeholder="Search for a rider"
          onFocus={() => setShowDropdown(true)}
          className="w-full px-4 focus:outline-none border border-neutral-600 bg-neutral-50 rounded py-4"
          onChange={(e) => setQuery(e.currentTarget.value)}
        />
      )}

      {showDropdown && (
        <button
          onClick={() => setShowDropdown(false)}
          className="text-sm text-primary-700 absolute top-[90px] right-0"
        >
          close
        </button>
      )}
      {showDropdown && (
        <div className="absolute top-[110px] border border-neutral-400 bg-neutral-50 rounded w-full mb-2 max-h-[200px] overflow-y-auto">
          {pilots
            .filter((pilot) =>
              `${pilot.user.firstName} ${pilot.user.lastName}`.includes(query)
            )
            .map((pilot, index) => (
              <PilotButton
                key={pilot.id}
                pilot={pilot}
                onClick={() => onSelectPilot(pilot.id)}
                className={`p-4 border-b flex justify-between items-center w-full ${
                  index !== pilots.length - 1
                    ? 'border-neutral-400'
                    : 'border-transparent'
                }`}
              />
            ))}
        </div>
      )}
    </div>
  );
};

const PilotButton = ({
  pilot,
  className,
  onClick,
}: {
  pilot: IPilot;
  className: string;
  onClick: () => void;
}) => {
  return (
    <button type="button" onClick={onClick} className={className}>
      <div className="flex items-center space-x-1">
        <div className="h-6 w-6 rounded-full bg-primary-50 flex items-center justify-center">
          <img src={VehicleIcon(pilot?.pilotInfo?.vehicleType)} alt="vehicle" />
        </div>
        <p className="bold text-sm">{`${pilot.user.firstName} ${pilot.user.lastName}`}</p>
      </div>

      <div className="flex items-center space-x-2">
        <div className="flex items-center space-x-1">
          <img src={orderBox} alt="orders" />
          <p className="text-xs">{pilot._count?.orders ?? 0}</p>
        </div>
        <div className="flex items-center space-x-1">
          <img src={mapDistance} alt="distance" />
          <p className="text-xs">
            {numeral(pilot.pilotInfo?.distanceFromKitchen).format('0,0.00') ??
              0}
            km
          </p>
        </div>
        <div className="flex items-center space-x-1">
          <img src={battery} alt="battery" />
          <p
            className={`text-xs bold ${
              pilot.pilotInfo?.batteryLevel &&
              pilot.pilotInfo?.batteryLevel > 30
                ? ''
                : 'text-primary-700'
            }`}
          >
            {pilot.pilotInfo?.batteryLevel ?? 0}%
          </p>
        </div>
      </div>
    </button>
  );
};
