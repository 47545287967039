import numeral from 'numeral';
import {
  DragEventHandler,
  FC,
  SyntheticEvent,
  useRef,
  useState,
  DragEvent,
} from 'react';
import { deleteIcon, fileIcon, uploadMedia } from '../../../assets/svg';

interface IUploadFile {
  onDrop: (event: DragEvent) => DragEventHandler<HTMLDivElement>;
  name: string;
  image?: any;
  deleteImage: () => void;
  isFile?: boolean;
  file?: any;
  acceptedFiles?: string;
}

export const UploadFile: FC<IUploadFile> = ({
  onDrop,
  name,
  image,
  deleteImage,
  isFile,
  file,
  acceptedFiles,
}) => {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (e: SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const onChangeCapture = () => {
    if (inputRef.current && inputRef.current.files)
      file(inputRef.current?.files[0]);
  };

  const onButtonClick = () => {
    inputRef.current && inputRef.current.click();
  };

  return (
    <>
      {(!isFile && !image) ||
      !(inputRef.current && inputRef.current.files?.length) ? (
        <div className="relative" onDragEnter={handleDrag}>
          <input
            ref={inputRef}
            type="file"
            id={name}
            name={name}
            accept={
              acceptedFiles ||
              'image/jpeg, image/jpg, image/png, .csv, .pdf, .xlsx'
            }
            onChangeCapture={onChangeCapture}
            className="hidden"
          />
          <label
            id="label-file-upload"
            htmlFor={name}
            className={`border border-neutral-200 shadow-xsmall rounded-md flex flex-col items-center py-5 w-full mt-4 ${
              dragActive ? 'cursor-copy bg-neutral-200' : 'bg-grey-white'
            }`}
          >
            <img src={uploadMedia} alt="upload-media" />
            <span>
              <button
                className="inline text-007 hover:opacity-80 transition duration-300"
                onClick={onButtonClick}
              >
                Upload {isFile ? 'document' : 'an image'}
              </button>
              <span> or drag and drop</span>
            </span>
            <p className="text-neutral-500 text-sm">
              {isFile ? '.XLSX up to 5MB' : 'PNG, JPG, up to 5MB'}
            </p>
          </label>
          {dragActive && (
            <div
              className="absolute h-full w-full rounded-md inset-0"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={onDrop}
            ></div>
          )}
        </div>
      ) : (
        <div className="flex space-x-5 items-center mt-10">
          {isFile ? (
            <div className="flex items-center space-x-3">
              <img src={fileIcon} alt="file-icon" />
              {inputRef.current && inputRef.current.files && (
                <div>
                  <p className="text-neutral-900 text-sm">
                    {inputRef.current?.files[0].name}
                  </p>
                  <div className="text-neutral-500 text-sm flex items-center space-x-1.5">
                    <span>
                      {numeral(inputRef.current?.files[0].size).format('0.0 b')}
                    </span>
                    <div className="rounded-full h-1 w-1 bg-neutral-500" />
                    <span>100% Uploaded</span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <img
              src={URL.createObjectURL(image)}
              className="border border-neutral-100 rounded-md h-20 w-20 object-cover"
              alt="meal"
            />
          )}

          <button className="flex items-center space-x-2" onClick={deleteImage}>
            <img src={deleteIcon} alt="delete" />
            <span className="text-primary-700">Delete</span>
          </button>
        </div>
      )}
    </>
  );
};

export const UploadFileNoDrag = ({
  selectedFile,
  handleFileChange,
}: {
  selectedFile: File | null;
  handleFileChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="border border-neutral-400 bg-neutral-50 rounded p-2 flex justify-between mt-4 items-center overflow-hidden relative">
      <div className="flex items-center">
        <input
          className="absolute opacity-0 w-full h-full left-0 top-0 z-10"
          type="file"
          onChange={handleFileChange}
        />
        <img className="mr-2" src={fileIcon} alt="file" />
        <div>
          <p className="text-xs font-bold satoshi ">
            {selectedFile ? selectedFile.name : 'Upload your document'}
          </p>
          {selectedFile ? (
            <p className="text-gray-400 satoshi font-medium mt-1 text-xs">
              {numeral(selectedFile.size).format('0.0 b')}
            </p>
          ) : (
            <p className="text-gray-400 satoshi font-medium mt-1 text-xs">
              PDF format &middot; Max. 5MB
            </p>
          )}
        </div>
      </div>

      {!selectedFile && (
        <button className="satoshi font-semibold px-4 py-2 rounded text-white bg-primary-700">
          Upload
        </button>
      )}
    </div>
  );
};
