import { SVGProps } from 'react';
export const SvgAlarm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? '#5FC381'}
      d="M1.204 3.02a.667.667 0 1 0 .943.943l1.65-1.65a.667.667 0 1 0-.943-.943l-1.65 1.65ZM8 6a.667.667 0 0 0-1.333 0v3.333c0 .369.298.667.666.667h2a.667.667 0 1 0 0-1.333H8V6Z"
    />
    <path
      fill={props.color ?? '#5FC381'}
      fillRule="evenodd"
      d="M8 1.333a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 8 1.333ZM2.667 8a5.333 5.333 0 1 1 10.666 0A5.333 5.333 0 0 1 2.667 8Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color ?? '#5FC381'}
      d="M12.195 1.529c.26-.26.683-.26.943 0l1.65 1.65a.667.667 0 1 1-.943.942l-1.65-1.65a.667.667 0 0 1 0-.942Z"
    />
  </svg>
);
