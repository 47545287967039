import React from 'react';
import { copy } from '../../../assets/svg';

export const CopyText = ({ text }: { text: string }) => {
  const [copied, setCopied] = React.useState(false);

  const onClickCopy = () => {
    setCopied(true);

    navigator.clipboard.writeText(text);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <button
      type="button"
      onClick={onClickCopy}
      className="flex space-x-1 items-center flex-shrink-0"
    >
      <p
        className={`text-sm semibold ${
          copied ? 'text-delivered' : 'text-neutral-700'
        }`}
      >
        {copied ? 'Copied!' : 'Copy'}
      </p>
      {!copied && <img src={copy} alt="copy" className="h-5" />}
    </button>
  );
};
