import React from 'react';
import { backArrow } from '../../../../../assets/svg';
import {
  couponStatus,
  discountBrandOption,
  discountType,
  foodItemServices,
  foodItemStatus,
  foodItemType,
} from '../../../../../constants';
import { useFoodItems } from '../../../../../hooks';
import { Services } from '../../../../../types';
import {
  AddButton,
  ImgInput,
  Loading,
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
  SecondaryButton,
  SelectInput,
  TextArea,
} from '../../../../components';
import { ImageUploader } from '../../../../components/image-uploader';
import { FoodItemImage } from './food-item-image';

const CreateFoodItem = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const {
    id,
    page,
    action,
    brands,
    images,
    payload,
    loading,
    required,
    newImage,
    categories,
    foodGroups,
    groupLoading,
    brandsLoading,
    discountOption,
    discountPayload,
    categoriesLoading,
    goBack,
    setPage,
    setImages,
    setPayload,
    setNewImage,
    onChangeBrand,
    createFoodItem,
    updateImageUrl,
    onChangeService,
    onGetInputValue,
    onChangeFoodGroup,
    onChangeCategories,
    onChangeCustomization,
    onGetDiscountInputValue,
  } = useFoodItems();

  return (
    <div>
      {(brandsLoading || groupLoading || categoriesLoading) && <Loading />}

      <div className="px-4 lg:px-8 py-8 w-full">
        <div className="flex justify-between">
          <div className="flex items-center">
            {[1, 2, 3].map((num) => (
              <PageIdentifier
                num={num}
                page={page}
                key={num}
                setPage={setPage}
              />
            ))}
          </div>
          <button
            className="flex items-center space-x-2 text-sm"
            onClick={closeDrawer}
          >
            <img src={backArrow} alt="back" />
            <span className="semibold">Close</span>
          </button>
        </div>

        <div className="mt-4">
          <div className="bg-white mt-2.5">
            {page === 1 && (
              <div>
                <div className="relative">
                  <p className="bold">Food item Images</p>
                  <div className="flex items-end space-x-1 mt-2">
                    <p className="text-xs text-gray-600 uppercase">
                      Upload type:
                    </p>
                    <button
                      onClick={() => setNewImage((prev) => !prev)}
                      className="uppercase bold text-primary-700 text-xs z-10"
                      type="button"
                    >
                      {newImage ? 'Upload new image' : 'Input image URL'}{' '}
                      &#8595;
                    </button>
                  </div>

                  {!newImage ? (
                    <ImgInput
                      name="imageUrl"
                      label="Food item primary image"
                      containerClass="w-full"
                      value={payload.imageUrl ?? ''}
                      onChange={onGetInputValue}
                    />
                  ) : (
                    <div className="mt-7">
                      <ImageUploader
                        name="imageUrl"
                        getUpload={(val) =>
                          setPayload({ ...payload, imageUrl: val })
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="mt-6">
                  <p className="bold text-sm">Add more images</p>

                  {images.map((image, index) => (
                    <FoodItemImage
                      key={index}
                      index={index}
                      image={image}
                      images={images}
                      setImages={setImages}
                      updateImageUrl={updateImageUrl}
                    />
                  ))}

                  <AddButton
                    onClick={() => setImages([...images, ''])}
                    text="Add an image"
                  />
                </div>

                <PrimaryInput
                  onChange={onGetInputValue}
                  value={payload?.name}
                  placeholder="Enter item name"
                  required
                  name="name"
                  label="What is the name of the food item?"
                  className="w-full"
                />

                <TextArea
                  onChange={onGetInputValue}
                  value={payload?.description ?? ''}
                  placeholder="Enter item description"
                  name="description"
                  label="Describe the food item"
                  className="w-full col-span-2"
                />

                <PrimarySelect
                  onChange={onGetInputValue}
                  options={foodItemType}
                  optionLabel="label"
                  value={payload.comboItem ? 1 : 0}
                  optionValue="value"
                  label="Food item type"
                  name="comboItem"
                  placeholder="Select food item type"
                  className="w-full"
                />

                <SelectInput
                  name="categories"
                  label="What categories can you find this food item in?"
                  placeholder="Select categories"
                  options={categories ?? []}
                  handleChange={onChangeCategories}
                  getOptionValue={(item) => `${item.id}`}
                  getOptionLabel={(item) => item.name}
                  value={categories?.filter((s) =>
                    payload.categories?.includes(s.id)
                  )}
                  className="z-40 relative"
                />

                <SelectInput
                  singleSelect
                  name="brand"
                  label="What brand owns this food item?"
                  placeholder="Select Brand"
                  options={brands ?? []}
                  handleChange={onChangeBrand}
                  getOptionValue={(item) => `${item.id}`}
                  getOptionLabel={(item) => item.name}
                  value={brands?.find((s) => payload.brand === s.id)}
                  className="z-30 relative"
                />

                <SelectInput
                  name="services"
                  label="What delivery services applies to this food item?"
                  placeholder="Select service(s)"
                  options={foodItemServices}
                  handleChange={onChangeService}
                  getOptionValue={(item) => `${item.value}`}
                  getOptionLabel={(item) => item.label}
                  value={foodItemServices.filter((s) =>
                    payload.services.includes(s.value as Services)
                  )}
                  className="z-20 relative"
                />

                <PrimaryInput
                  onChange={onGetInputValue}
                  value={`${payload?.price}`}
                  placeholder="Enter item price"
                  required
                  name="price"
                  label="What is the price of the food item?"
                  className="w-full"
                  type="number"
                />

                <PrimarySelect
                  onChange={onGetInputValue}
                  options={foodItemStatus}
                  optionLabel="label"
                  value={payload.status}
                  optionValue="value"
                  label="Food item status"
                  name="status"
                  placeholder="Select status"
                  className="w-full"
                />
              </div>
            )}

            {page === 2 && (
              <div className="relative">
                <SelectInput
                  name="foodGroups"
                  label="What food groups does this item have?"
                  placeholder="Select food groups"
                  options={foodGroups?.data ?? []}
                  handleChange={onChangeFoodGroup}
                  getOptionValue={(item) => `${item.id}`}
                  getOptionLabel={(item) => item.name ?? ''}
                  value={foodGroups?.data?.filter((g) =>
                    payload.foodGroups?.includes(g.id!)
                  )}
                  className="z-30 relative"
                />

                <SelectInput
                  name="customisations"
                  label="What customizations can be applied to this food item?"
                  placeholder="Select customizations"
                  options={foodGroups?.data ?? []}
                  handleChange={onChangeCustomization}
                  getOptionValue={(item) => `${item.id}`}
                  getOptionLabel={(item) => item.name ?? ''}
                  value={foodGroups?.data?.filter((g) =>
                    payload.customisations?.includes(g.id!)
                  )}
                  className="z-20 relative"
                />
              </div>
            )}

            {page === 3 && action !== 'create' && (
              <div>
                <p className="bold">Discounts</p>
                <p className="uppercase text-primary-700 text-xs bold">
                  add a discount to this item &#8595;
                </p>

                <div className="grid sm:grid-cols-2 gap-4 w-full">
                  <PrimarySelect
                    value={discountOption}
                    onChange={onGetDiscountInputValue}
                    options={discountType}
                    optionLabel="label"
                    optionValue="value"
                    label="Discount Type"
                    name="type"
                    placeholder="Select type"
                    className="w-full"
                  />

                  <PrimaryInput
                    onChange={onGetDiscountInputValue}
                    value={
                      discountOption === 'amount'
                        ? `${discountPayload.flat}`
                        : `${discountPayload.percent}`
                    }
                    placeholder="Enter Discount Amount"
                    name="amount"
                    label="Discount Amount"
                    type="number"
                    max={discountOption === 'percent' ? 100 : undefined}
                    className="w-full"
                  />

                  <PrimaryInput
                    onChange={onGetDiscountInputValue}
                    value={`${discountPayload.rounding}`}
                    placeholder="Enter rounding (approximation value)"
                    name="rounding"
                    label="Rounding"
                    type="number"
                    className="w-full"
                  />

                  <PrimarySelect
                    value={discountPayload?.enabled ? '1' : '0'}
                    onChange={onGetDiscountInputValue}
                    options={couponStatus}
                    optionLabel="label"
                    optionValue="value"
                    label="Discount Status"
                    name="enabled"
                    placeholder="Select status"
                    className="w-full"
                  />

                  {payload.brand && (
                    <PrimarySelect
                      value={discountPayload?.brandId ? '1' : '0'}
                      onChange={onGetDiscountInputValue}
                      options={discountBrandOption}
                      optionLabel="label"
                      optionValue="value"
                      label="Do you want to apply the discount to the full brand?"
                      name="brandId"
                      placeholder="Do you want to apply the discount to the full brand?"
                      className="w-full"
                    />
                  )}
                </div>
              </div>
            )}

            <div className="flex items-center justify-between space-x-4 mt-12">
              <SecondaryButton
                text={page === 1 ? 'Cancel' : 'Back'}
                type="button"
                onClick={() => goBack(closeDrawer)}
              />

              {page === 3 ? (
                <PrimaryButton
                  loading={loading}
                  onClick={createFoodItem}
                  disabled={!required || loading}
                  text={`${id ? 'Update' : 'Create'} food item`}
                  type="submit"
                />
              ) : (
                <>
                  <PrimaryButton
                    loading={loading}
                    onClick={createFoodItem}
                    disabled={!required || loading}
                    text={`${id ? 'Update' : 'Create'} food item`}
                    type="submit"
                  />

                  <SecondaryButton
                    text="Next page"
                    type="button"
                    onClick={() => setPage((prev) => prev + 1)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFoodItem;

const PageIdentifier = ({
  page,
  num,
  setPage,
}: {
  page: number;
  num: number;
  setPage: (x: number) => void;
}) => {
  const isActive = page === num;
  const isDone = page > num;

  return (
    <div key={num} className="flex items-center">
      <button
        type="button"
        onClick={() => setPage(num)}
        className={`border text-xs h-6 w-6 rounded-full ${
          isActive
            ? 'border-primary-100 bg-primary-50 text-primary-300'
            : isDone
            ? 'border-primary-300 bg-primary-300 text-primary-50'
            : 'border-neutral-50 bg-neutral-100 text-neutral-600'
        }`}
      >
        {num}
      </button>{' '}
      {num !== 3 && <div className="w-8 h-1 bg-neutral-100" />}
    </div>
  );
};
