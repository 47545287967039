import React, { FC } from 'react';
import { PrimaryButton } from './buttons';

interface INoData {
  title: string;
  description?: string;
  onclick?: () => void;
  buttonText?: string;
  containerClassName?: string;
  secondaryButton?: React.ReactNode;
}

export const NoData: FC<INoData> = ({
  title,
  description,
  onclick,
  buttonText,
  containerClassName,
  secondaryButton,
}) => {
  return (
    <div
      className={`${
        containerClassName ||
        'flex flex-col items-center justify-center flex-grow -mt-10 lg:-mt-10 z-0'
      }`}
    >
      <h2 className="bold text-[#222222] text-xl mt-2">{title}</h2>
      <p className="text-[#374151] text-sm w-8/12 sm:w-5/12 xl:w-3/12 mx-auto text-center">
        {description}
      </p>
      {buttonText && (
        <PrimaryButton text={buttonText} onClick={onclick} classNames="mt-4" />
      )}

      {secondaryButton}
    </div>
  );
};
