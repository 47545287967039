import React, { KeyboardEvent, SyntheticEvent, useState } from 'react';
import { logo } from '../../../assets/svg';
import { useAppDispatch } from '../../../hooks';
import { resetPassword } from '../../../store/slices';
import { PrimaryButton, PrimaryInput } from '../../components/shared';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResetPasswordParams } from '../../../types';

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const [required, setRequired] = useState<boolean>(false);
  const [payload, setPayload] = useState<ResetPasswordParams>({
    code: searchParams.get('code') ?? '',
    password: '',
    confirmPassword: '',
  });

  const _handleForgot = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!required) return;

    dispatch(resetPassword({ payload }));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _handleForgot();
    }
  };

  const onGetInputValue = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({ ...payload, [name]: value });
  };

  React.useEffect(() => {
    const { code, password, confirmPassword } = payload;
    setRequired(
      code.length > 0 &&
        password.length > 0 &&
        confirmPassword.length > 0 &&
        confirmPassword === password
    );
  }, [payload]);

  React.useEffect(() => {
    const code = searchParams.get('code');
    setPayload({ ...payload, code: code ?? '' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className="h-screen bg-grey-2 sm:py-12 lg:py-32 flex items-center justify-center">
      <form
        className="flex flex-col items-stretch justify-center bg-white rounded-xl h-full w-full sm:h-auto p-12 sm:w-8/12 lg:w-7/12 xl:w-4/12"
        onSubmit={_handleForgot}
      >
        <img src={logo} alt="logo" className="h-10 w-10 mx-auto" />
        <h1 className="text-neutral-800 bold text-center text-2xl">
          Forgot your password?
        </h1>

        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="Enter new password"
          required
          name="password"
          label="Password"
          className="w-full"
          type="password"
        />

        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="Confirm new password"
          required
          name="confirmPassword"
          label="Password confirmation"
          className="w-full"
          type="password"
        />

        <PrimaryButton
          disabled={!required}
          text="Reset password"
          type="submit"
          classNames="mt-10 medium"
        />

        <span className="text-center">
          <button
            type="button"
            onClick={() => navigate('/auth/login')}
            className="mt-5 text-primary-700 medium focus:outline-none"
          >
            Login
          </button>
        </span>
      </form>
    </div>
  );
};

export default ResetPassword;
